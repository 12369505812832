import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LandingSocialComponent } from './landing-social.component';
import { HttpClientModule } from '@angular/common/http';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  declarations: [
    LandingSocialComponent
  ],
  exports: [
    LandingSocialComponent
  ],
  providers: []
})

export class LandingSocialModule {

}
