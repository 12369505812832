
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CountryLink } from '../model/countrylink.model';

@Injectable({
  providedIn: 'root'
})
export class LandingSocialService {
  constructor(private _http: HttpClient) { }

  public getLinks(code: string) {
    const params: { stylecode: string } = { stylecode: code };
    const url = 'http://globalapi.dev.basic.net/Social/GetCountryLinkByStyle';
    return this._http.get<CountryLink[]>(url, {params: params});
  }

}
