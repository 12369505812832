import { Component, OnInit } from '@angular/core';
import { LandingSocialService } from './service/landing-social.service';
import { CountryLink } from './model/countrylink.model';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-landing-social',
  templateUrl: './landing-social.component.html',
  styleUrls: ['./landing-social.component.scss']
})
export class LandingSocialComponent implements OnInit {

  private random_backgroud = Math.floor(Math.random() * 17) + 1;

  public links: any;
  public img_assets;

  public linkTo;

  constructor(private service: LandingSocialService, private route: Router, private activeRoute: ActivatedRoute) {
    this.img_assets = 'assets/backgound/' + this.random_backgroud + '.jpg';
    if (!this.activeRoute.snapshot.paramMap.get('code')) {
      route.navigate(['301EFU0']);
    } else {
      route.navigate([this.activeRoute.snapshot.paramMap.get('code')]);
    }
  }

  ngOnInit() {
    this.service.getLinks(this.activeRoute.snapshot.paramMap.get('code')).subscribe((data: CountryLink[]) => {
      if (data) {
        this.links = data.filter(cl => cl && cl.NavigateUrl);
      }
    });
  }

  public selectOption(linkTo: string) {
    this.linkTo = linkTo;
  }

  public navigateTo() {
    if (this.linkTo) {
      location.href = this.linkTo;
    }
  }
}
